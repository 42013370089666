// JavaScript Document

// Scripts written by __gulp_init_author_name__ @ __gulp_init_author_company__

import Swiper from "swiper";

// init swiper
if (document.querySelectorAll(".home-slider .swiper-container--hero .swiper-slide").length > 1) {
    new Swiper(".home-slider .swiper-container--hero", {
        // autoplay: {
        //     delay: 15000,
        // },
        autoplay: false,
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        speed: 150,
        keyboard: {
            enabled: true,
            onlyInViewport: true,
        },
    });
}

if (document.querySelectorAll(".swiper-container--staff .swiper-slide").length > 1) {
    new Swiper(".swiper-container--staff", {
        autoplay: {
            delay: 6000,
        },
        loop: true,
        pagination: {
            el: ".swiper-container--staff .swiper-pagination",
            clickable: true,
        },
        slidesPerView: 9,
        speed: 150,
        breakpoints: {
            991: {
                slidesPerView: 4,
            }
        },
        keyboard: {
            enabled: true,
            onlyInViewport: true,
        },
    });
}

if (document.querySelectorAll(".swiper-container--newreleases .swiper-slide").length > 1) {
    new Swiper(".swiper-container--newreleases", {
        autoplay: {
            delay: 6000,
        },
        loop: true,
        pagination: {
            el: ".swiper-container--newreleases .swiper-pagination",
            clickable: true,
        },
        slidesPerView: 9,
        speed: 150,
        breakpoints: {
            991: {
                slidesPerView: 4,
            }
        },
        keyboard: {
            enabled: true,
        },
    });
}
