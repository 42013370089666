// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const TARGETS = document.querySelectorAll(".search-form input[name=target]");

TARGETS.forEach((target) => {
    target.addEventListener("change", () => {
        if (target.value === "catalog") {
            target.closest(".search-form").setAttribute("target", "_blank");
            target.closest(".search-form__input").setAttribute("placeholder", "Enter a search phrase");
        } else {
            target.closest(".search-form").setAttribute("target", "_self");
            target.closest(".search-form__input").setAttribute("placeholder", "Search by keyword, title, or author");
        }
    }, { passive: true });
});
