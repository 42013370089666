// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";

// custom


jQuery(document).ready(function () {
    jQuery(document).on("nfFormReady", function () {
        jQuery("#nf-field-2").on("keypress", function (e) {
            if (e.which == 13) {
                jQuery("#nf-field-6").trigger("click");
            }
        });
    });
    if (document.documentMode === 10) {
        document.documentElement.className += " ie10";
    }
    else if (document.documentMode === 11) {
        document.documentElement.className += " ie11";
    }


    if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
        jQuery("body").addClass("mac-os");
    }
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") > -1) {
            jQuery("body").addClass("chrome");
        } else {
            jQuery("body").addClass("safari");
        }
    }

    jQuery(".menu-toggle").on("click", function () {
        jQuery("html").toggleClass("nav-open");
    });
    jQuery(".close-icon").on("click touchstart", function () {
        jQuery("html").removeClass("nav-open");
    });

    jQuery(".menu-toggle").on("click", function () {
        jQuery("body").toggleClass("menu-open");
    });
    jQuery(".overlay").on("click touchstart", function () {
        jQuery("body").removeClass("menu-open");
        jQuery(".header-menu ul li").removeClass("open");
    });
    jQuery(".close-icon").on("click touchstart", function () {
        jQuery("body").removeClass("menu-open");
        jQuery(".header-menu ul li").removeClass("open");
    });


    jQuery("#search_box").on("click", function () {
        jQuery(".submit-form").toggleClass("search-open");
        jQuery(".mobile__search-form__container").toggleClass("open");
    });
    jQuery("#search_box").on("click", function () {
        if (jQuery(".mobile__search-form__container").css("display").toLowerCase() == "block") {
            if (jQuery("#search-input-field").val()) {
                jQuery(".search-form").submit();
            }
        }
        jQuery(".search-form-mobile").slideToggle(300);
    });

    jQuery(".close-search").on("click", function () {
        jQuery(".search-form-mobile").slideUp(300);
    });

    jQuery(".default-content").find("p").each(function () {
        if (jQuery(this).text() == " ") {
            jQuery(this).addClass("blank-p");
        }
    });

    if (jQuery(window).width() < 768) {
        jQuery(".search-field").attr("placeholder", "Search");
    } else {
        jQuery(".search-field").attr("placeholder", "Search by keyword, title, or author");
    }
});

/* ========================== window Resize ============================ */
jQuery(window).on("resize", function () {
    if (jQuery(window).width() < 768) {
        jQuery(".search-field").attr("placeholder", "Search");
    } else {
        jQuery(".search-field").attr("placeholder", "Search by keyword, title, or author");
    }
});
