
function close_all(type, itemClass) {
    type.forEach((ele) => {
        ele.parentElement.classList.remove(itemClass);
    });
}
const dropdowns = document.querySelectorAll(".main-navigation .menu-item-has-children > a");

// custom
document.querySelectorAll(".close-icon, .overlay, .menu-toggle").forEach((element) => {
    element.addEventListener("click", function () {
        const body = document.querySelector("body");
        if (body.classList.contains("nav-open")) {
            body.classList.remove("nav-open");
        } else {
            body.classList.add("nav-open");
        }

        close_all(dropdowns, "sub-menu-open");
    });
});

dropdowns.forEach((element) => {
    element.addEventListener("click", function (e) {
        const elementState = element.parentElement.classList.contains("sub-menu-open");

        // for each dropdown item, remove the "sub-menu-open" class
        close_all(dropdowns, "sub-menu-open");

        // if the currently clicked link is contained by a .menu-item-has-children:not(.sub-menu-open)
        if (!elementState) {
            e.preventDefault();

            // hook in to the li wrapping the element we just clicked
            let elementTree = element.parentElement;

            // while we're inside of the menu:
            // if the element we are on contains EITHER "menu-item-has-children" or "sub-menu"
            while (elementTree.classList.contains("menu-item-has-children") || elementTree.classList.contains("sub-menu")) {

                // if the menu item is a wrapper for a sub-menu, add the sub-menu-open class
                if (elementTree.classList.contains("menu-item-has-children")) {
                    elementTree.classList.add("sub-menu-open");
                }
                elementTree = elementTree.parentElement;
            }
        }
    });
});
